.selectInput {
  width: 100%;
}

.backgroundColorBtn {
  width: 100%;
  text-align: left;
}

.backgroundColorInput {
  display: flex;
  align-items: center;
}

/* .inputColorStyle {
  width: 12;
  height: 12;
  border-radius: 2;
  margin-right: 5;
} */

.contendInput {
  min-height: 10rem;
}

@import '~antd/dist/antd.css';
@import 'react-quill/dist/quill.snow.css';

.site-page-header {
  background: #fff;
}
.site-layout-content {
  background: #fff;
  padding: 12px;
  margin-top: 6px;
  margin: 6px 0px 0px;
  overflow: initial;
}

.DefaultLayout {
  min-height: 100vh;
}

.form-wrapper {
  max-width: 500px;
  flex-grow: 1;
}

.row {
  display: flex;
  align-items: center;
  padding: 20px;
  p {
    margin-bottom: 0;
  }
  .name-wrapper {
    min-width: 150px;
    .name {
      font-weight: 800;
      margin-right: 20px;
    }
  }

  .value {
  }
}

.container {
  //   max-width: 1000px;
  //   border: 1px solid black;
  //   border-radius: 16px;
}

#react_quillll {
  .ql-container {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .ql-snow.ql-toolbar {
    display: block;
    background: #fafafa;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  .ql-editor {
    min-height: 125px;
    max-height: 500px;
    overflow-x: auto;
  }
}
